import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../components/Page';

import { Container, Box, Button, Card, Typography, CardContent, Grid, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';

import { get } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  minWidth: 275,
  margin: theme.spacing(2, 0)
}));

const ItemCard = ({item}) => {
  const _positive = item.daily.diff > 0;
  const _color = _positive ? "red" : "blue"
  return (
    <CardStyle>
      <CardContent>
        <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">{item.name}</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>{fNumber(item.daily.close)}원 <span style={{color: _color}}>({_positive && "+"}{item.daily.diff.toFixed(2)}%)</span></Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">{item.theme.info}</Typography>
        {/* <Box mt={2}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="caption">EPS</Typography>
              <Typography>{item.daily.eps}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">PER</Typography>
              <Typography>{item.daily.per.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">PBR</Typography>
              <Typography>{item.daily.pbr.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        </Box> */}
        <Box mt={2} display="flex" flexDirection="row-reverse">
          <Button component={RouterLink} variant="contained" to={`/stock/${item._id}`}>
            종목 상세
          </Button>
        </Box>
      </CardContent>
    </CardStyle>
  )
}

export default function Theme({date}) {
  const { id } = useParams();
  const [theme, setTheme] = useState();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (date) {
      get(`theme/${id}?date=${date}`).then((res) => {
        setTheme(res.data.theme);
        setItems(res.data.items);
        setLoading(false);
      })
    }
  }, [date])
  return (
    <Page title="Stockwagon | Theme">
      {loading && <LinearProgress />}
      <Container maxWidth="md">
        <Box mt={3}>
          <Typography variant="h3" gutterBottom>{theme?.name}</Typography>
          <Typography>{theme?.info}</Typography>
          <Box mt={3}>
            {/* <Typography variant="h6">해당 종목 리스트</Typography> */}
            {items.map((item) => <ItemCard key={item._id} item={item} />)}
          </Box>
        </Box> 
      </Container>
    </Page>
  );
}
