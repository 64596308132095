import { useEffect, useState } from 'react';
// material
import { Box, CircularProgress, Stack, Pagination } from '@mui/material';

import StockCard from '../components/StockCard';
import { get } from '../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';
// ----------------------------------------------------------------------

export default function Stocks({ date, query, stocks, setStocks, page, setPage, pages, loading, setLoading, openId, setOpenId }) {

  const handleChangePage = (newPage) => {
    setPage((p) => {
      if (p != newPage) {
        setLoading(true);
        get(`ticker?date=${date}&search=${query}&page=${newPage - 1}`).then((res) => {
          setStocks(res.data.list);
          setLoading(false);
        })
      }
      return newPage;
    })
  }

  return (
    <Box>

      { loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          {stocks.map((item) => (
            <StockCard
            key={item._id}
            stock={item}
            expanded={openId == item._id}
            onToggle={() => setOpenId(openId == item._id ? null : item._id)}
          />
          ))}
          <Stack spacing={2} my={2} alignItems="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={pages}
              page={page}
              onChange={(e, v) => handleChangePage(v)}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
