import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// material
import { styled } from '@mui/styles';

import "./App.css";

import ThemeConfig from './theme';
import AppHeader from './components/AppHeader';
import Home from './pages/Home';


const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
  }
}));

function App() {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, [])
  return (
    <ThemeConfig>
      <RootStyle>
        <AppHeader />
        <MainStyle>
          <ins 
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-5857936531233376"
            data-ad-slot="7696729521"
            data-ad-format='auto' 
          />
          <Home />
        </MainStyle>
      </RootStyle>
    </ThemeConfig>
  );
}

export default App;
