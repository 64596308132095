import { useEffect, useState } from 'react';
// material
import { Box, Pagination, Stack, CircularProgress } from '@mui/material';

import ThemeCard from '../components/ThemeCard';
import { get } from '../utils/api';
import InfiniteScroll from 'react-infinite-scroll-component';
// ----------------------------------------------------------------------

export default function Themes({ date, query, themes, setThemes, page, setPage, pages, loading, setLoading, openId, setOpenId }) {

  const handleChangePage = (newPage) => {
    setPage((p) => {
      if (p != newPage) {
        setLoading(true);
        get(`theme?date=${date}&search=${query}&page=${newPage - 1}`).then((res) => {
          setLoading(false);
          setThemes(res.data.list);
        })
      }
      return newPage;
    })
  }

  return (
    <Box p={2} alignItems="center">
      { loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Stack>
          {themes.map((item) => (
            <ThemeCard
              key={item._id}
              theme={item}
              expanded={openId == item._id}
              onToggle={() => setOpenId(openId == item._id ? null : item._id)}
            />
          ))}
          <Stack spacing={2} my={2} alignItems="center">
            <Pagination
              variant="outlined"
              shape="rounded"
              count={pages}
              page={page}
              onChange={(e, v) => handleChangePage(v)}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
}
