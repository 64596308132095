import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fCurrency(number, unit, highest=false) {
  let s = unit;
  let v = number;
  ["", "만", "억", "조", "경", "해"].forEach((unit, i) => {
    let left = v % 10000
    if (left > 0) {
      s = `${left}${unit}${s}`
    }
    v = Math.floor(v / 10000)
    if (highest && v == 0 && left > 0) {
      s = `${left}${unit}원`
    } 
  })
  return s;
}