import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Accordion, AccordionSummary, AccordionDetails, 
        Box, Card, CardActions, CardContent, 
        Typography, Button, Divider } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  minWidth: 275,
  margin: theme.spacing(1, 2)
}));

const DoubleLinedTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2, /* number of lines to show */
  WebkitBoxOrient: 'vertical'
}))

export default function ThemeCard({theme, expanded, onToggle}) {
  const positive = theme.diff > 0;
  const topItems = theme.items.sort((a, b) => b.daily?.diff - a.daily?.diff).slice(0, 3);
  return (
    <Box>
      <Accordion expanded={expanded} onChange={onToggle}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Box display="flex" flexDirection="row" alignItems="center" px={1}>
            <Typography variant="h4" component="div">
              {theme.name} ({theme.items.length})
            </Typography>
            <Typography variant="h6" color={positive ? "red" : "blue"} sx={{marginLeft: 2}}>
              {positive && "+"}{theme.diff.toFixed(2)}%
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent>
            <Typography variant="body2" color="text.secondary" >
              {theme.info}
            </Typography>
            <Divider sx={{ marginY: 2 }} />
            <Box>
              <Typography variant="h6" gutterBottom>주요 상승 종목</Typography>
              {topItems.map((item) => {
                const _positive = item.daily.diff > 0;
                const _color = _positive ? "red" : "blue"
                return (
                  <Box key={item._id} display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography>
                      <Icon color={_color} icon={`ant-design:caret-${_positive? "up" : "down" }-filled`} />
                      {item.name}
                    </Typography>
                    <Typography color={_color}>
                      {item.daily.diff.toFixed(2)}%
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </CardContent>
          <CardActions sx={{flexDirection: "row-reverse", padding: 2}}>
            <Button component={RouterLink} to={`theme/${theme._id}`} variant="contained">전체 {theme.items.length}개 종목 확인하기</Button>
          </CardActions>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}