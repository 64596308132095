import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Accordion, AccordionSummary, AccordionDetails, Grid,
        Box, Card, CardActions, CardContent, 
        Typography, Button, Divider } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import { fCurrency, fNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  minWidth: 275,
  margin: theme.spacing(1, 2)
}));

const DoubleLinedTypography = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2, /* number of lines to show */
  WebkitBoxOrient: 'vertical'
}))

export default function StockCard({stock, expanded, onToggle}) {
  const positive = stock.daily.diff > 0;
  const topItems = stock.themes.sort((a, b) => b.diff - a.diff).slice(0, 3);
  return (
    <Box>
      <Accordion expanded={expanded} onChange={onToggle}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Box display="flex" flexDirection="column" px={1}>
            <Box component={Typography} variant="h4">
              {stock.name} ({stock.themes.length})
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="h6">
                {fNumber(stock.daily.close)}
              </Typography>
              <Typography variant="h6" color={positive ? "red" : "blue"} sx={{marginLeft: 2}}>
                ({positive && "+"}{stock.daily.diff.toFixed(2)}%)
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent>
            {/* <DoubleLinedTypography variant="body2" color="text.secondary" >
              {stock.info}
            </DoubleLinedTypography> */}
            {/* <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption">업종</Typography>
                <Typography>{stock.industry}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">전일종가</Typography>
                <Typography>{fNumber(stock.daily.close)}원</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">EPS</Typography>
                <Typography>{stock.daily.eps}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">PER</Typography>
                <Typography>{stock.daily.per.toFixed(2)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="caption">PBR</Typography>
                <Typography>{stock.daily.pbr.toFixed(2)}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} /> */}
            <Box>
              {/* <Typography variant="h6" gutterBottom>주요 테마</Typography> */}
              {topItems.map((item) => {
                const _positive = item.diff > 0;
                const _color = _positive ? "red" : "blue"
                return (
                  <Box key={item._id} display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography>
                      <Icon color={_color} icon={`ant-design:caret-${_positive? "up" : "down" }-filled`} />
                      {item.name}
                    </Typography>
                    <Typography color={_color}>
                      {item.diff.toFixed(2)}%
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </CardContent>
          <CardActions sx={{flexDirection: "row-reverse", padding: 2}}>
            <Button component={RouterLink} to={`stock/${stock._id}`} variant="contained">종목 상세</Button>
          </CardActions>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}