import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/system';
import { Box, Stack, AppBar, Toolbar, Link, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha("#d9d9d9", 0.72),
  width: "100%",
  color: theme.palette.primary.main,
  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  // }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function AppHeader() {
  return (
    <RootStyle>
      <ToolbarStyle>
        <Link component={RouterLink} to="/" variant="h4">
          테마주웨건
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Button>Temp</Button>
        </Stack> */}
      </ToolbarStyle>
    </RootStyle>
  );
}
