import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from '../components/Page';
import { Container, Box, Button, Card, Typography, CardContent, LinearProgress,
        Link, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';
import { Chart } from "react-google-charts";


import { get } from '../utils/api';
import { fNumber } from '../utils/formatNumber';
// ----------------------------------------------------------------------

const CardStyle = styled(Card)(({ theme }) => ({
  minWidth: 275,
  margin: theme.spacing(2, 0)
}));

const ThemeCard = ({theme}) => {
  const _positive = theme.diff > 0;
  const _color = theme.diff == 0 ? "#000": theme.diff > 0 ? "red" : "blue"
  return (
    <CardStyle>
      <CardContent>
        <Box mb={2} display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h5">{theme.name}</Typography>
          <Typography sx={{ fontWeight: 'bold', color: _color }}>{_positive && "+"}{theme.diff.toFixed(2)}%</Typography>
        </Box>
        <Typography variant="body2" color="text.secondary">{theme.info}</Typography>
        <Box mt={2} display="flex" flexDirection="row-reverse">
          <Button component={RouterLink} variant="contained" to={`/theme/${theme._id}`}>
            테마 상세
          </Button>
        </Box>
      </CardContent>
    </CardStyle>
  )
}

export default function Stock({date}) {
  const { id } = useParams();
  const [stock, setStock] = useState();
  const [daily, setDaily] = useState();
  const [themes, setThemes] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("line");
  const [period, setPeriod] = useState('1M');
  const [loading, setLoading] = useState(false);
  const _positive = daily?.diff > 0;
  const _color = daily?.diff == 0 ? "#000": daily?.diff > 0 ? "red" : "blue"
  useEffect(() => {
    if (date) {
      setLoading(true);
      get(`ticker/${id}?date=${date}`).then((res) => {
        setStock(res.data.ticker);
        setThemes(res.data.themes);
        setDaily(res.data.daily);
        setLoading(false);
      })
    }
  }, [date])

  // useEffect(() => {
  //   if (stock) {
  //     fetch('http://data.krx.co.kr/comm/bldAttendant/getJsonData.cmd', {
  //       method: 'POST',
  //       mode: 'cors',
  //       headers: {
  //         "Access-Control-Allow-Origin": "*"
  //       },
  //       body: {
  //         bld: "dbms/MDC/STAT/standard/MDCSTAT02106",
  //         ddTp: period,
  //         isuCd: stock.isin
  //       }
  //     })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       console.log(data);
  //     });
  //     get(`ticker/${id}/chart?period=${period}`).then((res) => {
  //       // setChartData(res.data.map((d) => ({
  //       //   date: new Date(d.TRD_DD),
  //       //   close: +(d.TDD_CLSPRC).replace(/,/g, ''),
  //       //   open: +(d.TDD_OPNPRC).replace(/,/g, ''),
  //       //   high: +(d.TDD_HGPRC).replace(/,/g, ''),
  //       //   low: +(d.TDD_LWPRC).replace(/,/g, ''),
  //       //   volume: +(d.ACC_TRDVOL).replace(/,/g, '')
  //       // })))
  //       setChartData(res.data.map((d) => ([
  //         d.TRD_DD,
  //         +(d.TDD_LWPRC).replace(/,/g, ''),
  //         +(d.TDD_OPNPRC).replace(/,/g, ''),
  //         +(d.TDD_CLSPRC).replace(/,/g, ''),
  //         +(d.TDD_HGPRC).replace(/,/g, ''),
  //       ])))

  //       // console.log(res.data.map((d) => ({
  //       //   date: new Date(d.TRD_DD),
  //       //   close: +(d.TDD_CLSPRC).replace(/,/g, ''),
  //       //   open: +(d.TDD_OPNPRC).replace(/,/g, ''),
  //       //   high: +(d.TDD_HGPRC).replace(/,/g, ''),
  //       //   low: +(d.TDD_LWPRC).replace(/,/g, ''),
  //       //   volume: +(d.ACC_TRDVOL).replace(/,/g, '')
  //       // })))
  //     })
  //   }
  // }, [stock, period])

  return (
    <Page title="Stockwagon | Stock">
      {loading && <LinearProgress />}
      <Container maxWidth="md">
        {stock && (
          <Box mt={3}>
            <Link target="_blank" href={`https://finance.naver.com/item/main.nhn?code=${stock?._id}`} variant="h3" gutterBottom>{stock?.name}</Link>
            <Typography variant="h4" gutterBottom>
              {fNumber(daily?.close)} 
              <span style={{color: _color, marginLeft: 4}}>({_positive && "+"}{daily?.diff.toFixed(2)}%)</span>
            </Typography>
            {/* <Typography variant="caption">{stock?.industry}</Typography> */}
            {/* <Box mt={2}>
              <Typography>전일종가</Typography>
              <Typography variant="h4">{fNumber(daily?.close)}원</Typography>
            </Box> */}
            {/* <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h5">주가 차트</Typography>
              <Box display="flex" flexDirection="row">
                <FormControlLabel 
                  control={
                    <Switch 
                      checked={chartType == 'candle'}
                      onChange={(event) => setChartType(event.target.checked ? "candle" : "line")}
                    />
                  } 
                  label={chartType == "line" ? "라인" : "캔들"} 
                />
                <FormControl sx={{ marginRight: 4 }}>
                  <InputLabel>기간</InputLabel>
                  <Select
                    value={period}
                    label="period"
                    onChange={(event) => setPeriod(event.target.value)}
                  >
                    <MenuItem value="1W">1주일</MenuItem>
                    <MenuItem value="1M">1개월</MenuItem>
                    <MenuItem value="3M">3개월</MenuItem>
                    <MenuItem value="1Y">1년</MenuItem>
                    <MenuItem value="3Y">3년</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box> */}
            {/* <Box mb={2}>
              <Chart
                width={'100%'}
                height={350}
                chartType={chartType == "line" ? "LineChart" : "CandlestickChart"}
                loader={<div>Loading Chart</div>}
                data={chartType == "line" ? ([
                  ['day', 'price'],
                  ...chartData.map((d) => [d[0], d[3]])
                ]) : ([
                  ['day', '저가-고가, 시가-종가', 'high', 'open', 'close'],
                  ...chartData
                ])}
                options={{
                  legend: 'none',
                  colors: ["#000"],
                  candlestick: {
                    fallingColor: { strokeWidth: 0, fill: 'blue' },
                    risingColor: { strokeWidth: 0, fill: 'red' },
                  },
                }}
              />
            </Box> */}
            <Box mt={3}>
              {/* <Typography variant="h6">테마 리스트</Typography> */}
              {themes.sort((a, b) => b.diff - a.diff).map((item) => <ThemeCard key={item._id} theme={item} />)}
            </Box>
          </Box>
        )}
      </Container>
    </Page>
  );
}
