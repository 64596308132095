import { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
// material
import { Container, Tab, Box, Typography, OutlinedInput, Button, InputAdornment, IconButton, LinearProgress } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Close as CloseIcon, FastfoodOutlined } from '@mui/icons-material';
import { styled } from '@mui/system';
// components
import Page from '../components/Page';

import { get } from '../utils/api';
import Themes from './Themes';
import Theme from './Theme';
import Stocks from './Stocks';
import Stock from './Stock';

import moment from 'moment';
// ----------------------------------------------------------------------

const TabStyle = styled(Tab)(({ theme }) => ({
  fontSize: '1.2rem'
}));

export default function Home() {
  const [themes, setThemes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [themePage, setThemePage] = useState(1);
  const [stockPage, setStockPage] = useState(1);
  const [themePages, setThemePages] = useState(0);
  const [stockPages, setStockPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [themeLoading, setThemeLoading] = useState(false);
  const [stockLoading, setStockLoading] = useState(false);
  const [tab, setTab] = useState("theme");
  const [openId, setOpenId] = useState();
  const [date, setDate] = useState();
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const handleChange = (e, v) => setTab(v);
  
  useEffect(() => {
    setLoading(true);
    get('date').then((res) => {
      setDate(String(res.data));
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    })
  }, [])

  useEffect(() => {
    if (date) {
      setThemeLoading(true);
      setStocks([])
      get(`theme?date=${date}&search=${query}&page=0`).then((res) => {
        setThemes(res.data.list);
        setThemePages(res.data.numPages);
        setThemeLoading(false);
      })
      get(`ticker?date=${date}&search=${query}&page=0`).then((res) => {
        setStocks(res.data.list);
        setStockPages(res.data.numPages);
        setStockLoading(false);
      })
    }
  }, [date, query])

  return (
    <Switch>
      <Route exact path="/">
        <Page title="Stockwagon">
          {loading && <LinearProgress />}
          <Container maxWidth="sm">
            <Box mt={4}>
              <Typography variant="overline">기준일: {date && moment(date).format('YYYY-MM-DD')}</Typography>
              <Box display="flex" flexDirection="row" alignItems="center">
                <OutlinedInput 
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  onKeyPress={(event) => {
                    if (event.key == "Enter") {
                      setQuery(search)
                    }
                  }}
                  fullWidth
                  sx={{marginY: 2}}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearch("")
                          setQuery("")
                        }}
                        edge="end"
                        disabled={!search}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Button sx={{ m: 1, py: 2 }} variant="contained" onClick={() => setQuery(search)}>검색</Button>
              </Box>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example">
                    <TabStyle label="테마" value="theme" />
                    <TabStyle label="종목" value="stock" />
                  </TabList>
                </Box>
                <TabPanel value="theme" sx={{ paddingX: 0 }}>
                  <Themes
                    date={date}
                    query={query}
                    themes={themes}
                    setThemes={setThemes}
                    page={themePage}
                    setPage={setThemePage}
                    pages={themePages}
                    loading={themeLoading}
                    setLoading={setThemeLoading}
                    openId={openId}
                    setOpenId={setOpenId} />
                </TabPanel>
                <TabPanel value="stock">
                  <Stocks
                    date={date}
                    query={query}
                    stocks={stocks}
                    setStocks={setStocks} 
                    page={stockPage}
                    setPage={setStockPage}
                    pages={stockPages}
                    loading={stockLoading}
                    setLoading={setStockLoading}
                    openId={openId}
                    setOpenId={setOpenId}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </Page>
      </Route>
      <Route exact path="/theme/:id">
        <Theme date={date} />
      </Route>
      <Route exact path="/stock/:id">
        <Stock date={date} />
      </Route>
    </Switch>
  );
}
